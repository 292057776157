<template>
<b-modal id="modal-create-property-type" ref="modal-create-property-type" centered="centered" :title="(PropertyType.id ? 'Modifier': 'Ajouter') + ` une périodicité`" @ok="create">
    <div v-if="isCreatingPropertyType">
        <div class="text-center">
            <div class="spinner-border text-primary" role="status"><span class="sr-only">Chargement...</span></div><br />Chargement des détails...
        </div>
    </div>
    <validation-observer v-else ref="formCreatePropertyType">
        <b-row>
            <b-col cols="12" class="d-flex">
                <b-form-group label="Libellé *" label-for="Libellé" style="flex:1">
                    <validation-provider #default="{ errors }" name="Libellé" rules="required">
                    <b-form-input
                        id="label"
                        v-model="PropertyType.label"
                        :state="errors.length > 0 ? false : null"
                        class="not-autocomplete"
                        autocomplete="nope" aria-autocomplete="nope"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
        </b-row>
    </validation-observer>
    <template #modal-footer="{ ok, cancel }">
        <div class="w-100 mx-0">
            <!-- <b-button class="float-left" v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-if="PropertyType.id" variant="outline-danger" @click="archivePropertyTypeLocal(PropertyType.id, PropertyType.label)"><feather-icon icon="ArchiveIcon" /></b-button> -->
            <b-button class="float-right" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="ok()">{{PropertyType.id ? 'Modifier': 'Ajouter'}}</b-button>
            <b-button class="float-right mr-1" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" @click="cancel()">Annuler</b-button>
        </div>
    </template>
</b-modal>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  defineRule,
  configure,
  localize,
} from "vee-validate";
import VSwatches from "vue-swatches";
import { required, url, email } from "@validations";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import "vue-swatches/dist/vue-swatches.css";
import vSelect from "vue-select";
configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

export default {
  data() {
    return {
      required,
      url,
      email,
      PropertyType: {
        label: "",
        workspaceId: this.$store.getters.workspaceSelected.id,
      },
      unitList:[
        'day',
        'week',
        'quarter',
        'month',
        'year',
        'hour',
        'minute',
        'second',
        'millisecond'
      ]
    };
  },
  methods: {
    popupCreatePropertyTypeEvent(id){
      if(id>0){
        this.fetchPropertyType(id).then(res => {
          this.PropertyType = res
        })
      }else this.initializeForm()
    },
    initializeForm() {
      this.PropertyType = {
        label: "",
        workspaceId: this.$store.getters.workspaceSelected.id,
      };
    },
    create(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.formCreatePropertyType.validate().then((success1) => {
        if (success1) {
          if(this.PropertyType.id) this.updatePropertyType(this.PropertyType)
          else this.createPropertyType(this.PropertyType);
          this.$nextTick(() => {
            this.$refs["modal-create-property-type"].toggle("#toggle-btn");
          });
        }
      });
    },
    archivePropertyTypeLocal (id, label) {
      let _this = this
      this.$bvModal
        .msgBoxConfirm('Vous pourrez à tout moment restaurer cette périodicité en consultant la liste de vos archives.', {
          title:
            'Êtes-vous sûr de vouloir archiver la périodicité "' + label + '" ?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Archiver',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.archivePropertyType(id);
            this.$nextTick(() => {
              this.$bvModal.hide('modal-create-property-type')
            })
          }
          
        })
    },
    ...mapActions(["createPropertyType", "updatePropertyType", "fetchPropertyType","archivePropertyType"]),
  },
  computed: {
    ...mapGetters(["isCreatingPropertyType"]),
  },
  
  components: {
    ValidationProvider,
    ValidationObserver,
    VSwatches,
    vSelect
  },
  directives: {
    Ripple,
  },
};
</script>